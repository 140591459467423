import { RelatedArticles1ColumnWrapper } from './style';
import { P, H5, H3, Row } from '../../styleguide-remapped/styleguide-remapped';
import { uid } from 'react-uid';
import { ArrowRightIcon } from '../related-articles-2-columns/style';
import arrowRightSvg from '../../../assets/logos/arrow-right.svg';
import Breadcrumbs from '../../custom/breadcrumbs';
import Link from 'next/link';

const RelatedArticles1Column = ({ block, documentData, pageType, relatedCategories, relatedTopics, preview }) => {
  return (
    <RelatedArticles1ColumnWrapper id={`${block.__component}-${block.id}`}>
      {block.articles?.data?.filter((d) => d.id != documentData.id)?.length > 0 && <H3> {block.headline} </H3>}
      {block.articles?.data
        ?.filter((d) => d.id != documentData.id)
        .map((article, index) => {
          return (
            <div key={uid(article)} className="container">
              <Link href={'/artikel/' + article.attributes.slug}>
                <Row className="article-header">
                  <H5>{article.attributes.title}</H5>
                  <ArrowRightIcon src={arrowRightSvg} />
                </Row>
                <P className="description">{article.attributes.description}</P>
              </Link>
              <Breadcrumbs categories={relatedCategories} topics={relatedTopics} articles={[article]} breadcrumbKey={`${uid(article)}-${index}-b`} />
            </div>
          );
        })}
    </RelatedArticles1ColumnWrapper>
  );
};

export default RelatedArticles1Column;
