import { RelatedArticles2ColumnWrapper, ArrowRightIcon } from './style';
import { H3, H5, P, Column, Row } from '../../styleguide-remapped/styleguide-remapped';
import arrowRightSvg from '../../../assets/logos/arrow-right.svg';
import { uid } from 'react-uid';
import Breadcrumbs from '../../custom/breadcrumbs';
import Link from 'next/link';

const RelatedArticles2Columns = ({ block, documentData, pageType, relatedCategories, relatedTopics, preview }) => {
  const dynamicArticles = getImpressionBasedArticles(relatedTopics, documentData, preview);
  const allStaticArticles = [...block.articlesCol1.articles.data, ...block.articlesCol2.articles.data];

  const filteredDynamicArticles = dynamicArticles.filter((dynamic) => !allStaticArticles.some((staticArticle) => staticArticle.id === dynamic.id));

  let [filledCol1Articles, updatedDynamicArticles] = fillColumnWithDynamicArticles(block.articlesCol1.articles.data, filteredDynamicArticles);
  let [filledCol2Articles, _] = fillColumnWithDynamicArticles(block.articlesCol2.articles.data, updatedDynamicArticles);

  return (
    <RelatedArticles2ColumnWrapper className={pageType}>
      <H3>{block.headline}</H3>
      <Row>
        {articleColumn(filledCol1Articles, [...relatedCategories, documentData], relatedTopics)}
        {articleColumn(filledCol2Articles, [...relatedCategories, documentData], relatedTopics)}
      </Row>
    </RelatedArticles2ColumnWrapper>
  );
};

const getImpressionBasedArticles = (relatedTopics, category, preview) => {
  let articlesWithImpressions = [];
  relatedTopics.forEach((topic) => {
    if (topic.attributes?.category?.data?.id === category.id) {
      topic.attributes?.articles?.forEach((d) => articlesWithImpressions.push(...d.articles.data));
    }
  });

  return [...new Map(articlesWithImpressions.filter((d) => d.attributes.pageImpressionCount >= 1 && (preview && !d.attributes.publishedAt) || (!preview && d.attributes.publishedAt)).map((item) => [item['id'], item])).values()].sort((a, b) => b.attributes.pageImpressionCount - a.attributes.pageImpressionCount);
};

const fillColumnWithDynamicArticles = (columnArticles, dynamicArticles, desiredLength = 3) => {
  let remainingArticles = desiredLength - columnArticles.length;
  if (remainingArticles <= 0) return [columnArticles, dynamicArticles];

  let extraDynamicArticles = dynamicArticles.slice(0, remainingArticles);
  columnArticles.push(...extraDynamicArticles);

  return [columnArticles, dynamicArticles.slice(remainingArticles)];
};

const articleColumn = (articles, categories, topics) => (
  <Column all={12} xs={12} s={12} m={6} px={0} mx={0} className="column">
    {articles.map((article) => (
      <div className="container" key={uid(article)}>
        <Link href={'/artikel/' + article.attributes.slug}>
          <div className="article-container">
            <Row className="article-header">
              <H5>{article.attributes.title}</H5>
              <ArrowRightIcon src={arrowRightSvg} />
            </Row>
            <P className="description"> {article.attributes.description} </P>
          </div>
        </Link>
        <Breadcrumbs categories={categories} topics={topics} articles={[article]} breadcrumbKey={`${uid(article)}-b`} />
      </div>
    ))}
  </Column>
);

export default RelatedArticles2Columns;
