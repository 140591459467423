import { SectionWrapper, SectionsWrapper } from "./style";
import { P, H5, H3, Row, Column } from "../../styleguide-remapped/styleguide-remapped";
import { uid } from 'react-uid';
import { ArrowRightIcon } from "../related-articles-2-columns/style";
import arrowRightSvg from "../../../assets/logos/arrow-right.svg";
import Breadcrumbs from "../../custom/breadcrumbs";
import Link from 'next/link';

const Sections = ({ block, documentData, pageType, relatedCategories, relatedTopics, preview }) => {
  let leftSections = [];
  let rightSections = [];
  let leftCounter = 0;
  let rightCounter = 0;

  if (documentData.attributes.articles.length === 1) {
    return (
      <SectionsWrapper>
        <Row>
          <Column all={12} xs={12} s={12} m={12} px={0} mx={0}>
            {section(documentData.attributes.articles[0], documentData, relatedCategories, relatedTopics, preview)}
          </Column>
        </Row>
      </SectionsWrapper>
    );
  } else {
    Object.keys(block).filter(key => !isNaN(key)).map(key => block[key]).map((sc) => {
      if (rightCounter >= leftCounter) {
        leftSections.push(section(sc, documentData, relatedCategories, relatedTopics, preview));
        leftCounter += sc.articles.data.length;
      } else {
        rightSections.push(section(sc, documentData, relatedCategories, relatedTopics, preview));
        rightCounter += sc.articles.data.length;
      }
    });

    return (
      <SectionsWrapper>
        <Row>
          <Column all={12} xs={12} s={12} m={6} px={0} mx={0}>
            {leftSections}
          </Column>
          <Column all={12} xs={12} s={12} m={6} px={0} mx={0}>
            {rightSections}
          </Column>
        </Row>
      </SectionsWrapper>
    );
  }
};

const section = (sc, topic, relatedCategories, relatedTopics, preview) => {
  return (
    <SectionWrapper id={sc.id}>
      <H3> {sc.title} </H3>
      {sc.articles?.data.filter(d => (preview && !d.attributes.publishedAt) || (!preview && d.attributes.publishedAt)).map((article) => {
        const categoriesForArticle = [];
        const topicsForArticle = [topic];

        relatedTopics.forEach((relatedTopic) => {
          relatedTopic?.attributes?.articles.forEach((topicSection) => {
            if (topicSection.articles?.data.find(d => d.id === article.id) &&
              !topicsForArticle.find(d => d.id === relatedTopic.id)) {
              topicsForArticle.push(relatedTopic);
            }
          });
        });

        topicsForArticle.forEach((topicForArticle) => {
          if (topicForArticle?.attributes?.category?.data?.id && !categoriesForArticle.find(d => d.id === topicForArticle.attributes.category.data.id)) {
            categoriesForArticle.push(topicForArticle.attributes.category.data);
          }
        });

        return (
          <div key={uid(article)} className="container">
            <Link href={`/${categoriesForArticle[0].attributes.slug}/${topicsForArticle[0].attributes.slug}/${article.attributes.slug}`}>
              <Row className="article-header">
                <H5>{article.attributes.title}</H5>
                <ArrowRightIcon src={arrowRightSvg} />
              </Row>
              <P className="description">{article.attributes.description}</P>
            </Link>
            <Breadcrumbs categories={relatedCategories} topics={[...topicsForArticle]} articles={[article]} breadcrumbKey={`${uid(article)}-b`} />
          </div>
        )
      })}
    </SectionWrapper>
  );
}

export default Sections;
